import React, { FC } from "react";
import { Router, Switch, Route } from "react-router-dom";

import "./App.css";

import CurrentLangContextProvider from "./components/currentLangContext/CurrentLangContextProvider";

/** pages */
import { Landing } from "./pages/landing/Landing";
import SchoolLanding from "./components/school-landing/SchoolLanding";
import { StudentSchedulePage } from "./components/studentSchedule/StudentSchedule";
import { Availability } from "./pages/availability/Availability";
import { Statistics } from "./pages/stats/Statistics";
import { NotFound404 } from "./pages/not-found-404/NotFound404";

/** misc */
import { history } from "./utils/history";

const App: FC = () => (
	<>
		<CurrentLangContextProvider>
			<div className="App">
				<div>
					<Router history={history}>
						<Switch>
							<Route exact path="/" component={Landing} />

							<Route exact path="/:school/" component={SchoolLanding} />
							<Route exact path="/:school/avail" component={Availability} />
							<Route exact path="/:school/stats" component={Statistics} />
							<Route exact path="/:school/:participant" component={StudentSchedulePage} />

							<Route path="*" component={NotFound404} />
						</Switch>
					</Router>
				</div>
			</div>
		</CurrentLangContextProvider>
	</>
);

export default App;
