import React, { FC } from "react";
import { css } from "emotion";
import { useWindow } from "../../hooks/useWindow";

export type TopItem = {
	text: string;
	position: number;
};

export type Top3Props = {
	data: TopItem[][];
};

export const Top3: FC<Top3Props> = ({ data }) => {
	const { notDesktop, isDesktop } = useWindow();

	if (!data?.length) {
		return null;
	}

	return (
		<div
			className={css`
				margin: auto;
			`}
		>
			<div
				className={css`
					display: flex;
					justify-content: center;
					align-items: center;

					& > * {
						margin: 0rem;
					}

					#card-n2 {
						z-index: 30;
						rotate: -12deg;
						background: hsla(0, 0%, 75%, 0.8);

						margin-bottom: -6rem;
						margin-right: -1rem;
					}
					#card-n1 {
						z-index: 40;
						background: hsla(60, 100%, 70%, 1);
						margin-top: 0;
					}
					#card-n3 {
						z-index: 20;
						rotate: 17deg;
						background: hsla(26, 79%, 63%, 0.8);

						margin-bottom: -11rem;
						margin-left: -1.5rem;
					}

					${notDesktop} {
						flex-direction: column;

						& > * {
							rotate: 0deg !important;
							z-index: 1;
							margin: 1rem !important;
						}

						margin-bottom: 4rem;
					}

					margin-top: 2rem;
					margin-bottom: 10rem;

					font-weight: 600;
					line-height: 1.5rem;

					text-align: left;

					transition: 0.1s;
					&:hover {
						scale: 1.05;
					}
				`}
			>
				{isDesktop ? (
					<>
						<TopCard id="card-n2" items={data[1]} />
						<TopCard id="card-n1" items={data[0]} />
						<TopCard id="card-n3" items={data[2]} />
					</>
				) : (
					<>
						<TopCard id="card-n1" items={data[0]} />
						<TopCard id="card-n2" items={data[1]} />
						<TopCard id="card-n3" items={data[2]} />
					</>
				)}
			</div>
		</div>
	);
};

export const TopCard: FC<{ items: TopItem[] } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ items, ...rest }) => {
	return (
		<div
		 	{...rest}
			className={css`
				// min-width: 34%;
				height: 30vh;

				padding: 1rem;

				border: 1px solid black;
				border-radius: 4px;

				overflow: scroll;
			`}
		>
			<ul>
				{items.map(x => (
					<li key={x.text}>
						<span>{x.text}</span>
					</li>
				))}
			</ul>
		</div>
	);
};
