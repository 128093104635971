import React, { FC } from "react";
import { css, cx } from "emotion";

export type FullHeightProps = {} & React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

export const FullHeight: FC<FullHeightProps> = ({
	children, //
	className,
	...rest
}) => {
	return (
		<div
			{...rest}
			className={cx(
				css`
					min-height: 100vh;

					display: flex;
					flex-direction: column;
				`,
				className,
			)}
		>
			{children}
		</div>
	);
};
