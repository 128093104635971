import React, { FC } from "react";
import { Link } from "react-router-dom";
import { css, cx } from "emotion";

import { Navbar } from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import { LANDING_NO_AUTO_SWITCH_TO_SCHOOL } from "../../hooks/useSelectedSchool";
import { useTranslation } from "../../i18n/useTranslation";
import { FullHeight } from "../../components/layout/FullHeight";
import { DEFAULT_CONTACT_EMAIL } from "../../config";

export type NotFound404Props = {
	//
};

export const NotFound404: FC<NotFound404Props> = () => {
	const t = useTranslation();

	const urlPath = window.location.pathname;
	const url = window.location.toString();

	return (
		<FullHeight>
			<Navbar disableWarningAboutOutdatedData />

			<div
				className={css`
					margin: auto;
				`}
			>
				<h2
					className={css`
						margin-top: 4rem;
					`}
				>
					{t("Page not found")}...
				</h2>

				<p
					className={css`
						margin-top: 0;
					`}
				>
					{t("Got URL")}:
				</p>
				<span
					className={css`
						background: hsl(50, 100%, 75%);
						color: hsl(50, 100%, 20%);
						font-weight: 600;

						padding: 0.2rem 0.4rem;
						margin: 0.2rem 0.4rem;
						line-height: 1.4rem;

						display: inline-block;
						max-width: min(60ch, 80vw);
						text-align: left;
						text-wrap: wrap;
						word-wrap: break-word;
					`}
				>
					{urlPath}
				</span>

				<div
					className={css`
						margin-top: 5rem;

						& > * + * {
							margin-top: 1.5rem;
						}
					`}
				>
					<a
						href={`mailto:${DEFAULT_CONTACT_EMAIL}?subject=${t("404-notify-of-a-problem--mailto-subject")}&body=${t("404-notify-of-a-problem--mailto-body")({ url })}`}
						className={cx("btn btn--round")}
					>
						{t("Notify of a problem")}
					</a>

					<Link
						to={`/?${LANDING_NO_AUTO_SWITCH_TO_SCHOOL}`}
						className={cx("btn btn--round")}
					>
						{t("Go back to the main page")}
					</Link>
				</div>
			</div>

			<Footer
				className={css`
					margin-top: auto;
				`}
			/>
		</FullHeight>
	);
};
