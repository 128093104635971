import React, { FC } from "react";
import { css } from "emotion";

import { Navbar } from "../navbar/Navbar";
import Footer from "../footer/Footer";

export type BasicPageLayoutProps = {
	FooterProp?: typeof Footer;
};

export const BasicPageLayout: FC<BasicPageLayoutProps> = ({ children, FooterProp = <Footer /> }) => {
	return (
		<div
			className={css`
				display: flex;
				flex-direction: column;
				min-height: 100vh;
			`}
		>
			<Navbar />

			{children}

			<div
				className={css`
					margin-top: auto;
				`}
			>
				{FooterProp}
			</div>
		</div>
	);
};
