import React, { FC } from "react";
import { css } from "emotion";
import { scheduleDaysArray, ScheduleDay } from "../../utils/selectSchedule";

import { dayIndexPrettyRoman } from "./day-index";
import { useTranslation } from "../../i18n/useTranslation";
import { BadgeBeta } from "../../common/Badge";
import { StickyInfoOrWarningAboutFreshOrOutdatedData } from "../navbar/StickyInfoOrWarningAboutFreshOrOutdatedData";

export interface DaySelectorMobileProps {
	selectedDay: ScheduleDay | undefined;
	handleClick: (e: React.MouseEvent, day: ScheduleDay) => any;
}

interface DaySelectorDesktopProps {
	selectedDay: ScheduleDay;
	onSelectDay: (day: ScheduleDay) => void
}


export const DaySelectorMobile: FC<DaySelectorMobileProps> = ({ selectedDay, handleClick }) => {
	const t = useTranslation();
	return (
		<div
			className={css`
				width: 100%;
				position: sticky;
				top: 0;
				background: #fff;
				z-index: 1;

				& > * {
					border-left: none !important;
				}

				& > *:last-child {
					border-right: none !important;
				}
			`}
		>
				<button
					type="button"
					key={"*"}
					onClick={(e) => handleClick(e, "*")}
					className={
						css`
							border-bottom: 0px;
							display: inline-block;
							width: 100%;
							min-height: 30px;
						` + ` btn${"*" === selectedDay ? " selected" : ""}`
					}
				>
					<span>{t("weekday")("*")}</span>
				</button>

			{scheduleDaysArray.filter((val) => typeof val === 'number').map((dayIndex, _, arr) => (
				<button
					type="button"
					key={dayIndex}
					onClick={(e) => handleClick(e, dayIndex)}
					className={
						css`
							display: inline-block;
							width: ${100 / arr.length}%;
							min-height: 30px;
						` + ` btn${dayIndex === selectedDay ? " selected" : ""}`
					}
				>
					<span>{dayIndexPrettyRoman(dayIndex)}</span>
				</button>
			))}
		</div>
	)
};

export const DaySelectorDesktop: FC<DaySelectorDesktopProps> = ({ selectedDay, onSelectDay }) => {
	const t = useTranslation();
	return (<nav
		className={css`
			/* background: lightcyan; */
			display: flex;
			flex-direction: column;
			flex: 0;
			flex-basis: 2;

			min-width: 15em; /** TODO - does not prevent the shrinking when we want it to prevent:/ */

			height: 100%;
			max-height: 100%;
			overflow-x: hidden;
			overflow-y: auto;

			border-right: 1px solid #000; /* Y/N? */
		`}
	>
		<ul
			className={css`
				display: flex;
				flex-direction: column;

				height: fit-content;

				align-items: center;
				justify-content: center;

				& > * {
					flex: 1;
				}

				& > * + * {
					border-top: 1px solid #000;
				}

				border-bottom: 1px solid #000;
			`}
		>
			{scheduleDaysArray.map((dayIndex) => (
				<li
					key={dayIndex}
					className={css`
									flex-grow: 1;
									width: 100%;


									/* ${dayIndex === selectedDay && "border-left: 0.75em solid #000;"} */
									position: relative;
								`}
				>
					{dayIndex === selectedDay && (
						<span
							className={css`
								position: absolute;
								left: 0;
								top: 0;

								width: 0.75em;
								height: 100%;

								background: #000;
							`}
						/>
					)}

					<button
						type="button"
						onClick={(_e) => onSelectDay(dayIndex)}
						className={css`
										display: flex;

										${dayIndex === "*" ? `
											align-items: center;
											justify-content: center;
										` : `
											margin-left: 1.5rem;
											align-items: left;
											justify-content: left;
										`}


										width: 100%;
										height: 100%;
										font-size: 2em;

										/* ${dayIndex === selectedDay && "font-weight: 700; background: #000; color: lightcyan;"} */
									`}
					>
						<span>{t("weekday")(dayIndex)}</span>
						{dayIndex === "*" &&
							<BadgeBeta  className={css`
								font-size: 1rem;
								top: 6px;
								right: 2px;
								font-weight: 550;
							`}
							/>}
					</button>
				</li>
			))}
		</ul>
		<div className={css`
			margin-top: auto;
			margin-left: 1em;

			display: flex;
			flex-direction: column;
			align-items: bottom;
			justify-content: left;

			min-height: fit-content;
			height: max-content;
			`}
		>
			<div className={css`height: 1em; width:100%`}/>
			{/**Here we can also add links for:
					- download screenshot
					- subscribe to lesson change notifications
					- etc
					- extra links if logged in as admin
						- modify*/}
			{/* {Array(4).fill(1).map((_, i) => {
				return <button key={i} className={css`width: fit-content; font-size:1.5em;`}>Button #{i}</button>
			})} */}
			<div className={css`height: 0.5em; width:100%`}/>
		</div>
		<StickyInfoOrWarningAboutFreshOrOutdatedData/>
	</nav>)
};
