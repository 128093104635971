import React, { FC } from "react";
import { css } from "emotion";

import { findTopK, getDefaultTopFriend } from "@turbo-schedule/common";

import { BasicPageLayout } from "../../components/layout/BasicPageLayout";
import { useFetchParticipants, useFetchTopFriend } from "../../hooks/useFetchers";
import { useWindow } from "../../hooks/useWindow";
import { useTranslation } from "../../i18n/useTranslation";
import { Search } from "../../components/navbar/Search";
import { OnSelect, ParticipantListList } from "../../components/studentSchedule/ParticipantList";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import { Top3, TopItem } from "./Top3";

export type StatisticsProps = {};

export const Statistics: FC<StatisticsProps> = () => {
	const t = useTranslation();
	const { notDesktop } = useWindow();

	const [viewingAs, setViewingAs] = useViewingAs();
	const onSelect: OnSelect = (participant) => {
		setViewingAs(participant);
	}

	const [participants] = useFetchParticipants([], []);

	return (
		<BasicPageLayout>
			<main
				className={css`
					margin-top: 2rem;
					margin-bottom: 6rem;

					& > * + * {
						margin-top: 2rem;
					}
				`}
			>
				<h1>{t("Statistics")}</h1>

				{/* TODO: button to clear */}
				<ViewingAs viewingAs={viewingAs} setViewingAs={setViewingAs} />

				{/* TODO: collapse whole list if selected */}
				<ParticipantListList participants={participants} searchString={viewingAs} onSelect={onSelect}>

				</ParticipantListList>

				{!viewingAs ? null : (
					<>
						<div
							className={css`
								margin-top: 6rem;

								${notDesktop} {
									margin-top: 3rem;
								}
							`}
						/>
						<StatisticsOf viewingAs={viewingAs} />
					</>
				)}
			</main>
		</BasicPageLayout>
	);
};

/**
 * TODO:
 * use this everywhere, as in:
 * - when user selects who's schedule to view
 * - etc
 *
 * TODO: allow providing a URL query param `viewingAs` for default value
 * for sharing links
 */
export const useViewingAs = () => useLocalStorage<string>("turbo-schedule.viewing-as", "");

export type ViewingAsProps = {
	viewingAs: string;
	setViewingAs: (viewingAs: string) => void;
}

const ViewingAs: FC<ViewingAsProps> = ({ viewingAs, setViewingAs }) => {
	const t = useTranslation();

	return <div
		className={css`
			display: flex;
		`}
	>
		<div className={css`
			margin: auto;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		`}>
			<h2>{t("Viewing as")}...</h2>
			<Search searchString={viewingAs} setSearchString={setViewingAs} />
		</div>
	</div>;
}

export type StatisticsOfProps = {
	viewingAs: string;
}

const StatisticsOf: FC<StatisticsOfProps> = ({ viewingAs }) => {
	const t = useTranslation();
	const { desktop, notDesktop } = useWindow();

	const [topFriend] = useFetchTopFriend(getDefaultTopFriend, [viewingAs], {
		urlCtx: {
			of: viewingAs,
		},
	});

	/**
	 * TODO FIXME: re-enable when figure out how to
	 * get rid of the local "could not proxy to server" error.
	 */
	// useAddMostRecentParticipantOnPageChange(viewingAs);

	const top3data: TopItem[][] = !topFriend.friends.length
		? []
		: findTopK(3, topFriend.friends, "commonLessonsLen").map(
			(x, i) => x.map((y): TopItem => ({ text: y.name, position: i }))
		);

	console.log({top3data})

	return <div className={css`
		display: flex;
		flex-direction: column;
	`}>
		<h2 className={css`
			padding: 1rem;
		`}>
			{t("Top friends of")(viewingAs)}:
		</h2>

		<Top3 data={top3data} />

		<section>
			<div
				className={css`
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							margin: auto;
							${desktop} {
								width: 30%;
							}
							${notDesktop} {
								padding: 0 2rem;
							}

							& > * + * {
								margin-top: 1rem;
							}

							position: relative;
						`}
			>
				<div
					className={css`
								display: flex;
								justify-content: space-between;

								font-weight: 500;

								position: sticky;
								top: 0;
								z-index: 1;

								background: white;
								padding: 0.8rem 0 0.5rem 0;
								border-radius: 0 0px 4px 4px;
							`}
				>
					<span>{t("Name")}</span>
					<span>{t("# of common lessons")}</span>
				</div>

				<ul
					className={css`
								display: flex;
								flex-direction: column;

								text-align: left;
							`}
				>
					{topFriend.friends.map((x) => (
						<li
							key={x.name}
							className={css`
										padding: 0.05rem 0 0.05rem 0;

										&:hover {
											background: #eee8;
										}
									`}
						>
							<div
								className={css`
											display: flex;
											justify-content: space-between;
										`}
							>
								<span>{x.name}</span>
								<span>{x.commonLessonsLen}</span>
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	</div>;
}
